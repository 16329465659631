import React, { useState } from "react";

import { v4 as uuidv4 } from "uuid";

import { handleImageUpload } from "../../firebase/fbcrud";

import "./dashboard.css";

export default function Home(props) {
  const [imageFile, setImageFile] = useState(null);

  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [uploadProgress, setUploadProgress] = useState("");

  const onSaveData = async (event) => {
    event.preventDefault();

    if (!imageFile) {
      setErrorMessages((error) => {
        return [...error, "You must upload image of the food"];
      });
      return;
    }

    const docId = uuidv4();
    const filename = uuidv4();

    await handleImageUpload(
      docId,
      filename,
      imageFile,
      setUploadProgress,
      setErrorMessages,
      setSuccessMessage
    );
    setErrorMessages([]);
  };

  return (
    <div className="dashboard-home">
      {uploadProgress !== "" && (
        <p className="success-message">{uploadProgress}</p>
      )}
      {successMessage !== "" && (
        <p className="success-message">{successMessage}</p>
      )}

      {errorMessages.length > 0 &&
        errorMessages.map((error) => (
          <p className="error-message" key={error}>
            {error}
          </p>
        ))}
      <div className="dashboard-home-content">
        <form className="home-upload-form" onSubmit={onSaveData}>
          <label htmlFor="file-upload">Upload an image</label>
          <input
            id="file-upload"
            type="file"
            onChange={(event) => setImageFile(event.target.files[0])}
            accept="image/png, image/jpeg, image/jpg, video/mp4, video/webm"
          />

          <div>
            <input type="submit" value="Submit" />
          </div>
        </form>
        <table></table>
      </div>
    </div>
  );
}
