import { createContext, useReducer, useContext } from "react";

import orderReducer, { initialState } from "../reducer/orderReducer";

export const OrderContext = createContext(initialState); // You must pass the initial state to the context

export function OrderContextProvider({ children }) {
  //You are defining provider here, you must use plural just like components
  const [state, dispatch] = useReducer(orderReducer, initialState); //yes, you pass the reducer you created with initial state.
  //you must not consume/define your reducer anywhere else. Everything should be defined this context file except you define certain outside (like reducer) to keep your code clean.
  //SO DO NOT DEFINE A REDUCER WITH SAME NAME IN YOUR COMPONENT(S).EVERYTHING HAS TO START IN THIS CONTEXT FILE EXCEPT K
  //DEFINE THE REDUCER OUTSIDE TO KEEP THINGS CLEAN.
  //You define your action creators here (functions that receive the data you're dispatching to the reducer)
  //Remember you use functions (our event handlers here) to pass data to the context.
  const addToCart = (order) => {
    const updateCart = [...state.orders, order];
    dispatch({
      type: "ADD_TO_CART",
      payload: {
        updateCart,
      },
    });
  };

  const removeFromCart = (order) => {
    const updateCart = state.orders.filter(
      (orderParam) => orderParam.id !== order.id
    );

    dispatch({
      type: "REMOVE_FROM_CART",
      payload: {
        updateCart,
      },
    });
  };

  const value = {
    orders: state.orders,
    addToCart,
    removeFromCart,
  };

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
}

//Last but not least, you consume the context
//Yes, you can do inside the component(s) that are consuming the context but for the most part
// developers consume it here and return the consumed context and just grab the data from the consumed context.
//you basically create a custom hook
const useOrder = () => {
  const context = useContext(OrderContext);
  if (context === undefined) {
    throw new Error("useOrder must be used within OrderContext");
  }
  return context;
};

export default useOrder;
