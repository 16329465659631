import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { CiMenuBurger } from "react-icons/ci";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { FaHome, FaSignInAlt, FaSignOutAlt } from "react-icons/fa";
import { AiFillMail } from "react-icons/ai";

import { auth } from "../../firebase/firebase";

import Contact from "../contact/Contact";
import Auth from "../auth/Auth";

import logo from "../../assets/images/logo.png";
import "./Navigation.css";

export default function Navigation() {
  const navigate = useNavigate();
  const [isBurgerMenu, setIsBurgerMenu] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isContact, setIsContact] = useState(false);
  const [isAuth, setIsAuth] = useState(false);

  const headerRef = useRef(null);
  const homeRef = useRef(null);
  const contactRef = useRef(null);
  const signInRef = useRef(null);
  const signOutRef = useRef(null);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(user ? true : false);

      return () => unsub();
    });
  }, []);

  const handleHeaderObserver = (entries) => {
    try {
      const [entry] = entries;

      if (entry.isIntersecting) {
        headerRef.current.classList.add("navigation-container");
        homeRef.current.style.color = "#fff";
        contactRef.current.style.color = "#fff";
        signInRef.current.style.color = "#fff";
        signOutRef.current.style.color = "#fff";
      } else {
        headerRef.current.classList.remove("navigation-container");
        homeRef.current.style.color = "#4844c1";
        contactRef.current.style.color = "#4844c1";
        signInRef.current.style.color = "#4844c1";
        signOutRef.current.style.color = "#4844c1";
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const headerReference = headerRef.current;
    const options = {
      root: null,
      rootMargin: "10px",
      threshold: 1,
    };

    const observer = new IntersectionObserver(handleHeaderObserver, options);

    if (headerReference) observer.observe(headerReference);

    return () => {
      if (headerReference) observer.unobserve(headerReference);
    };
  }, [headerRef]);

  return (
    <header ref={headerRef}>
      <nav className="main-nav">
        <ul className="menu-list">
          <li className="menu-list-item" onClick={() => navigate("/")}>
            <img src={logo} alt="Melse Renovation Logo" />
          </li>

          <li className="menu-list-item">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? "active" : undefined)}
              end
              ref={homeRef}
            >
              <FaHome size={30} />
              <span>Home</span>
            </NavLink>
          </li>
          <li className="menu-list-item">
            <NavLink
              onClick={() => {
                setIsAuth(false);
                setIsContact(!isContact);
              }}
              className={({ isActive }) => (isActive ? "active" : undefined)}
              end
              ref={contactRef}
            >
              <AiFillMail size={30} />
              <span>Contact Us</span>
            </NavLink>
          </li>
          {/*
          <li className="menu-list-item">
            {isAuthenticated && (
              <NavLink
                to="/dashboard"
                className={({ isActive }) => (isActive ? "active" : undefined)}
              >
                Dashboard
              </NavLink>
            )}
          </li>*/}
          <li className="menu-list-item">
            {isAuthenticated ? (
              <NavLink
                onClick={() => signOut(auth)}
                className={({ isActive }) => (isActive ? "active" : undefined)}
                ref={signOutRef}
              >
                <FaSignOutAlt size={30} />
                <span>Sign Out</span>
              </NavLink>
            ) : (
              <NavLink
                onClick={() => {
                  setIsContact(false);
                  setIsAuth(!isAuth);
                }}
                className={({ isActive }) => (isActive ? "active" : undefined)}
                ref={signInRef}
              >
                <FaSignInAlt size={30} />
                <span>Sign In</span>
              </NavLink>
            )}
          </li>
        </ul>

        <div className="hamburger-menu-container">
          <div className="hamburger-logo-burger-container">
            <div className="hamburger-logo-container">
              <img src={logo} alt="Rapadou Logo" />
            </div>
            <CiMenuBurger
              className="hamburger-menu-icon"
              onClick={() => setIsBurgerMenu(!isBurgerMenu)}
            />
          </div>
          {isBurgerMenu && (
            <ul className="hamburger-menu-list">
              <li className="menu-list-item hamburger-menu-list--color">
                <NavLink
                  onClick={() => {
                    setIsAuth(false);
                    setIsContact(!isContact);
                  }}
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                  end
                >
                  Contact Us
                </NavLink>
              </li>

              {/*
              <li className="menu-list-item">
                <NavLink
                  to="/dashboard"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                >
                  Dashboard
                </NavLink>
              </li>*/}
              <li className="menu-list-item hamburger-menu-list--color">
                {isAuthenticated ? (
                  <NavLink
                    onClick={() => signOut(auth)}
                    className={({ isActive }) =>
                      isActive ? "active" : undefined
                    }
                  >
                    Sign Out
                  </NavLink>
                ) : (
                  <NavLink
                    onClick={() => {
                      setIsContact(false);
                      setIsAuth(!isAuth);
                    }}
                    className={({ isActive }) =>
                      isActive ? "active" : undefined
                    }
                  >
                    Sign In
                  </NavLink>
                )}
              </li>
            </ul>
          )}
        </div>
      </nav>
      {isContact && (
        <Contact
          isContact={isContact}
          onSetIsContact={setIsContact}
          onSetIsAuth={setIsAuth}
        />
      )}

      {isAuth && (
        <Auth
          isAuth={isAuth}
          onSetIsAuth={setIsAuth}
          onSetIsContact={setIsContact}
        />
      )}
    </header>
  );
}
