import React, { useEffect, useState } from "react";

import Photos from "../components/photos/Photos";
import { getPhotos } from "../firebase/fbcrud";
import ConfirmationPrompt from "../components/popup/ConfirmationPrompt";

import "./Home.css";

export default function Home() {
  const [photos, setPhotos] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      try {
        await getPhotos(setPhotos, setErrorMessage);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <section className="home">
      {errorMessage !== "" && (
        <ConfirmationPrompt confirmation={errorMessage} />
      )}
      <Photos photos={photos} />
    </section>
  );
}
