import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import { OrderContextProvider } from "./context/OrderContext";
import ErrorBoundary from "./utils/errorboundary";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <OrderContextProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </OrderContextProvider>
  </React.StrictMode>
);
