import React from "react";
import Navigation from "../components/navigation/Navigation";

import "./ErrorPage.css";
export default function ErrorPage() {
  return (
    <>
      <Navigation />
      <main className="error-page">
        <h2>An error occured!</h2>
        <p>Could not find this page!</p>
      </main>
    </>
  );
}
