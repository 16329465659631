import React from "react";

import "./photos.css";

const Photos = ({ photos }) => {
  return (
    <div className="photos">
      {photos.map(({ id, imageUrl }) => {
        if (imageUrl.includes(".mp4") || imageUrl.includes(".webm")) {
          return (
            <div key={id}>
              <video controls preload="none">
                <source src={imageUrl} />
                <source src={imageUrl} />
              </video>
            </div>
          );
        }
        return (
          <div key={id}>
            <img src={imageUrl} alt="Melse Renovation LLC" loading="lazy" />
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(Photos);
