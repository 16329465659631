import { Outlet } from "react-router-dom";
import Navigation from "../components/navigation/Navigation";

export function RootLayout() {
  return (
    <>
      <Navigation />
      <main className="content">
        <Outlet />
      </main>
    </>
  );
}
