export const initialState = {
  //we export state here so we can can import it to our context to initialize the context
  id: 0,
  tableNum: 0,
  category: "",
  description: "",
  imageUrl: "",
  price: 0,
  sideDetails: [],
  orders: [],
  orderCompleted: false,
};

function orderReducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case "ADD_TO_CART":
      return {
        ...state,
        orders: payload.updateCart,
      };

    case "REMOVE_FROM_CART":
      return {
        ...state,
        orders: payload.updateCart,
      };

    default:
      throw new Error("No such action was found");
  }
}

export default orderReducer;
