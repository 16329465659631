// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC3vz8df3InxLvyCRvNz_0x9eQcBpfKZm8",
  authDomain: "melserenovationapp-2ed33.firebaseapp.com",
  projectId: "melserenovationapp-2ed33",
  storageBucket: "melserenovationapp-2ed33.appspot.com",
  messagingSenderId: "344605338332",
  appId: "1:344605338332:web:5f39bb9c67b7a186c96219",
  measurementId: "G-EX42LFVVZ1",
};
// Initialize Firebase
initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getFirestore();
export const storage = getStorage();
