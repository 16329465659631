import React, { useState, useEffect } from "react";
import { FaHome } from "react-icons/fa";
import { signOut, onAuthStateChanged } from "firebase/auth";

import { auth } from "../../firebase/firebase";

import Home from "./Home";

import "./dashboard.css";

export default function Dashboard() {
  const [isHome, setIsHome] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user?.reloadUserInfo?.email.includes("melseadmin")) {
        setIsAdmin(user && true);
      }
    });
    return () => unsub();
  }, []);

  return (
    isAdmin && (
      <div className="dashboard">
        <aside className="left-side">
          <ul>
            <li
              onClick={() => {
                setIsHome(true);
              }}
            >
              <FaHome size={30} />
            </li>

            <li onClick={() => signOut(auth)}>Logout</li>
          </ul>
        </aside>

        <main className="right-side">{isHome && <Home />}</main>
      </div>
    )
  );
}
