import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import {
  setDoc,
  doc,
  getDocs,
  collection,
  onSnapshot,
  query,
  limit,
} from "firebase/firestore";

import { db, storage } from "./firebase";

export const getPhotos = async (setData, setErrorMessages) => {
  try {
    let datarr = [];
    let data = {};
    const querySnapshot = await getDocs(collection(db, "photos"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      //if you recall, the id is always seperate from the data, you have to get it from the document....

      data = { ...doc.data(), id: doc.id }; //unpack doc.data() and add the id

      datarr.push(data);
    });

    setData(datarr);
  } catch (error) {
    console.log(error);
    setErrorMessages(error);
  }
};

export const handleImageUpload = async (
  docId,
  filename,
  file,
  setUploadProgress,
  setErrorMessages,
  setSuccessMessage
) => {
  try {
    // Upload file and metadata to the object 'images/mountains.jpg'

    let storageRef = ref(storage, "images/" + `${filename}`);

    if (file.name.includes(".mp4")) {
      storageRef = ref(storage, "images/" + `${filename}.mp4`);
    }

    if (file.name.includes(".webm")) {
      storageRef = ref(storage, "images/" + `${filename}.webm`);
    }

    const uploadTask = uploadBytesResumable(storageRef, file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        if (typeof progress === Number) {
          setUploadProgress("Upload is " + progress + "% done");
        }
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            setSuccessMessage("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            setSuccessMessage("Upload is running");
            break;
          default:
            console.log("Be patient!!!");
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            setErrorMessages([]);
            setErrorMessages((errorfb) => {
              return [
                ...errorfb,
                "User doesn't have permission to access the object",
              ];
            });
            break;
          case "storage/canceled":
            // User canceled the upload
            setErrorMessages([]);
            setErrorMessages((errorfb) => {
              return [...errorfb, "User canceled the upload"];
            });
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            setErrorMessages([]);
            setErrorMessages((errorfb) => {
              return [
                ...errorfb,
                "Unknown error occurred, inspect error.serverResponse",
              ];
            });
            break;

          default:
            setErrorMessages([]);
            setErrorMessages((errorfb) => {
              return [...errorfb, "Server error occurred"];
            });
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          //console.log("File available at", downloadURL);

          setDoc(doc(db, "photos", docId), {
            imageUrl: downloadURL,
          });
          setSuccessMessage("Upload is complete");
        });
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export function deleteImageFile(imageUrl, setSuccessMessage, setErrorMessages) {
  // Create a reference to the file to delete
  const desertRef = ref(storage, imageUrl);

  // Delete the file
  deleteObject(desertRef)
    .then(() => {
      // File deleted successfully
      setSuccessMessage("File deleted successfully");
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
      setErrorMessages((errorlocal) => {
        return [...errorlocal, error];
      });
      return;
    });
}

//GetData with OnSnapShot
export function getAllData(dbname, setAllData, setErrorMessages) {
  try {
    const q = query(collection(db, dbname));

    onSnapshot(q, (querySnapshot) => {
      const data = [];
      querySnapshot.docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      setAllData(data);
    });
  } catch (error) {
    console.log(error);
  }
}

export function getInitialData(dbname, setInitialData) {
  try {
    const q = query(collection(db, dbname), limit(3));

    onSnapshot(q, (querySnapshot) => {
      const data = [];
      querySnapshot.docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      setInitialData(data);
    });
  } catch (error) {
    console.log(error);
  }
}
