import React, { useRef, useState } from "react";
import { IoMdClose, IoMdPhonePortrait, IoMdMail } from "react-icons/io";
import axios from "axios";

import "./contact.css";

export default function Contact({ isContact, onSetIsContact, onSetIsAuth }) {
  const emailFromRef = useRef("");

  const emailSubjectRef = useRef("");
  const emailBodyRef = useRef("");

  const [errorMessage, setErrorMessage] = useState("");

  const [successMessage, setSuccessMessage] = useState("");

  const handleSendEmail = async (event) => {
    try {
      event.preventDefault();

      const emailFrom = emailFromRef.current.value;

      const emailSubject = emailSubjectRef.current.value;
      const emailBody = emailBodyRef.current.value;

      if (emailFrom === "") {
        setErrorMessage("Your email cannot be empty");
        return;
      }

      if (emailSubject === "") {
        setErrorMessage("Your email will be sent without a subject line");
      }

      if (emailBody === "") {
        setErrorMessage("You forgot to enter the message for your email");
        return;
      }
      //   const response = await axios.post(`${baseURL}`, {
      const response = await axios.post(`/`, {
        emailFrom,
        emailSubject,
        emailBody,
      });

      setErrorMessage("");
      emailFromRef.current.value = "";
      emailSubjectRef.current.value = "";
      emailBodyRef.current.value = "";

      setSuccessMessage(response.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    isContact && (
      <div className="contact">
        <IoMdClose
          className="icon-delete"
          onClick={() => {
            onSetIsAuth(false);
            onSetIsContact(false);
          }}
        />

        <address className="contact-us-address">
          <h4>Contact Us: </h4>
          <p>
            <IoMdPhonePortrait />
            (717) 420 4524
          </p>

          <p>
            <IoMdMail />
            <p>Please use form below to contact us via email.</p>
          </p>
        </address>
        <form onSubmit={handleSendEmail}>
          <p>{errorMessage !== "" ? errorMessage : ""}</p>
          <p>{successMessage !== "" ? successMessage : ""}</p>
          <input type="email" placeholder="From" ref={emailFromRef} />

          <input type="text" placeholder="Subject" ref={emailSubjectRef} />
          <textarea
            spellCheck="true"
            placeholder="Type in your message"
            ref={emailBodyRef}
          ></textarea>

          <div>
            <button>Submit</button>
          </div>
        </form>
      </div>
    )
  );
}
